// import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import AddOnPackages from "../data/addOns.json"
import Video from "./video"

export const AddOnItem = styled.section`
  background-color: ${props => (props.number % 2 === 0 ? "#EDEDED" : "white")};
  display: flex;
  flex-direction: column;
  min-height: 35vh;

  @media screen and (min-width: 1024px) {
    .addoncontent {
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 5vh;
      padding: 0 5%;
      /* margin-left: ${props => (props.number % 2 === 0 ? "auto" : 0)}; */

      /* align-items: ${props => (props.number % 2 === 0 ? "" : "flex-end")};
      
      */
      h3,
      p {
        width: 100%;
        text-align: ${props => (props.number % 2 === 0 ? "end" : "start")};
      }
      ul {
        display: flex;
        flex-direction: column;
        width: 65%;
        padding: 0;
        margin: 0;
        justify-content: flex-end;
        /* align-self: flex-start; */

        align-self: ${props =>
          props.number % 2 === 0 ? "flex-end" : "flex-start"};
        li {
          text-align: ${props => (props.number % 2 === 0 ? "end" : "start")};
        }
      }
    }
  }
  @media screen and (min-width: 1440px) {
    .addoncontent {
      padding: 0 20%;
    }
  }
`

const AddOns = () => {
  return (
    <div className="flex flex-col justify-items-center items-center  gap-5">
      {AddOnPackages.map(item => {
        return (
          <AddOnItem key={item.id} number={item.id} className="p-10  w-full">
            <div className="addoncontent flex flex-col">
              <h3 className="text-3xl">{item.title}</h3>
              <p className=" my-5 text-lg lg:text-xl tracking-widest">
                + {item.price}
              </p>

              <ul>
                {item.description.map((x, i) => (
                  <div>
                    <li
                      key={i}
                      className={`leading-loose text-lg ${
                        item.description.length > 1 ? " bullets" : "list-none"
                      }`}
                    >
                      {x}
                    </li>
                  </div>
                ))}
              </ul>
              {item.title === "Engagement Session" ? (
                <>
                  <Video
                    title={"dsakdjs"}
                    videoSrc={
                      "https://player.vimeo.com/video/440526781?h=c8a61a0083"
                    }
                  />
                </>
              ) : (
                <></>
              )}
              {item.title === "Social Media Teaser" ? (
                <>
                  <Video
                    title={"dsakdjs"}
                    videoSrc={
                      "https://player.vimeo.com/video/636968098?h=0b123f2c39"
                    }
                  />
                </>
              ) : (
                <></>
              )}
              {item.title === "Documentary Film Edit" ? (
                <>
                  <Video
                    title={"Documentary Film Edit"}
                    videoSrc={
                      "https://player.vimeo.com/video/668389093?h=793795465c"
                    }
                  />
                </>
              ) : (
                <></>
              )}
              {item.title === "Ceremony & Toast Edit" ? (
                <>
                  <Video
                    title={"Ceremony & Toast Edit"}
                    videoSrc={
                      "https://player.vimeo.com/video/675694989?h=c47db10502"
                    }
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </AddOnItem>
        )
      })}
    </div>
  )
}

export default AddOns
