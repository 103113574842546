import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import AddOns from "../components/addOns"
import StyledBackgroundSection from "../components/imageHero"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import Packages from "../data/packages.json"
const Pricing = () => {
  const data = useStaticQuery(graphql`
    query Packages {
      # allPackagesJson {
      #   edges {
      #     node {
      #       id
      #       title
      #       price
      #       details
      #       isMostPopular
      #       offeredWeekdays
      #     }
      #   }
      # }
      desktop: file(relativePath: { eq: "flowers-min.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      flowers: file(relativePath: { eq: "addon.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const imageData = data.desktop.childImageSharp.fluid
  const imageData2 = data.flowers.childImageSharp.fluid
  // console.log("Packages", Packages)
  return (
    <Layout>
      <SEO title="The Essential | Los Angeles Film Studio" />
      <div className="min-h-screen text-black">
        <StyledBackgroundSection
          className=""
          imageSrc={imageData}
          title={"The Essential"}
        />
        <p className="leading-relaxed md:w-3/4 my-5 px-5 mt-20 md:mx-auto md:mt-20 text-center md:text-2xl">
          To maintain a streamlined approach, we have chosen not to provide
          packages. Instead, we offer an "essential" service that caters to the
          needs of 95% of our couples. Delivery date will take up to 90 days
          after the wedding. We also provide additional enhancements should you
          require further customization.
        </p>

        <div className="text-gray-700 flex flex-col justify-center md:justify-around lg:justify-center items-center py-10 md:mt-24">
          <div className="text-center my-20 lg:w-1/3 lg:my-10">
            <h1 className="font-bold text-3xl uppercase">The Essential</h1>

            <hr className="w-1/4 mx-auto border-black my-5" />
            <ul className="space-y-4 lg:space-y-6 text-lg">
              <li className="">
                Complete coverage from morning bridal preparations through until
                the evening party.
              </li>
              <li className="">Filmed by two Cinematographers. </li>
              <li className="">
                A five-minute cinematic film professionally edited to music,
                using narrative audio recorded from your ceremony.
              </li>
              <li className="">
                18-20 minute cinematic film professionally edited to music,
                using narrative audio recorded from your ceremony and reception.
              </li>

              <li className="">
                Separate Ceremony Film, including all precessional readings,
                vows, and ring exchanges.
              </li>
              <li className="">Separate Speeches Film</li>
              <li className="">
                All footage is professionally color graded and filmed in full
                High Definition.
              </li>

              <li className="">
                Online showcase page to share with friends/family and full
                downloads of all files.
              </li>
            </ul>
            <p className="font-bold mb-12 text-lg mt-2">$7,999</p>
            <Link to="/contact" className="mt-3">
              <button className="bg-gray-900 rounded px-5 py-1 text-white">
                Book Now
              </button>
            </Link>
          </div>
          <div className="text-center my-20 lg:w-1/3 lg:my-10">
            <h1 className="font-bold text-3xl uppercase">Enhancements</h1>

            <hr className="w-1/4 mx-auto border-black my-5" />
            <ul className="space-y-4 lg:space-y-6 text-lg">
              <li className="">Rehearsal Dinner / Welcome Party / Brunch</li>
              <li className="">Two Cinematographers. </li>
              <li className="">Up to 4 Hours of Coverage</li>
            </ul>
            <p className="font-bold mb-12 text-lg mt-2">$1999</p>
            <Link to="/contact" className="mt-3">
              <button className="bg-gray-900 rounded px-5 py-1 text-white">
                Book Now
              </button>
            </Link>
          </div>
        </div>
        {/* <div className="bg-gray-500 h-48 w-full"> */}
        <StyledBackgroundSection
          className="h-48 w-full"
          imageSrc={imageData2}
          title={"Bespoke"}
        />
        {/* </div> */}
        <AddOns />
      </div>
    </Layout>
  )
}

export default Pricing
